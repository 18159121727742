@charset "UTF-8";

.contact-txt {
  text-align: center;
  @include fz(14);
  line-height: 25px;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
  @include mqSP {
    font-size: 13px;
    margin-bottom: 25px;
    padding: 0 10px;
    br {
      display: none;
    }
  }
}


.form-section {
  background-color: rgba(255,255,255,0.9);
  box-shadow:0px 0px 5px 2px rgba(154,154,154,0.2);
  width:100%;
  margin: 0 auto 35px auto;
  padding: 55px;
  &__header {
    margin-bottom:35px;
    text-align: center;
    ._heading {
      @include fz(30);
      font-family:$font-family-second;
    }
  }
  &__body {
    ._txt {
      text-align: center;
      @include fz(16);
      line-height: 30px;
      margin-bottom:50px;
      &:last-child {
        margin-bottom: 0;
        color: #808080;
        font-size: 15px;
        line-height: 1.466;
      }
      a {
        text-decoration: underline;
      }
    }
    sup {
      @include fz(5);
      vertical-align: super;
    }
  }
  @include mqSP {
    padding: 30px 20px;
    width: calc(100% - 20px);
    margin: 0 auto 20px auto;
    &__header {
      margin-bottom:25px;
      ._heading {
        @include fz(20);
        letter-spacing: -.01em;
      }
    }
    &__body {
      ._txt {
        @include fz(12);
        line-height: 22px;
        text-align: left;
        margin-bottom:20px;
        &:last-child {
          font-size: 12px;
        }
      }
      sup {
        @include fz(5);
      }
    }
  }
}

%is-error {
  background-color: #fff7f8;
  background-image: url(../img/icon_error.png);
  background-repeat: no-repeat;
  background-size: 18px auto;
  background-position: 98% center;
  border: 1px solid #cd2929;
}

.form-cont {
  display:table;
  width:780px;
  margin:0 auto;
  .error {
    display: block;
    font-size: 13px;
    padding-top: 5px;
    color: red;
  }
  dl {
    display:table-row;
  }
  dt,dd {
    display:table-cell;
    @include fz(16);
    padding:15px;
  }
  dt {
    text-align:right;
  }
  input {
    @include fz(14);
    padding:10px;
    border: 1px solid #CCCCCC;
    &.is-error {
      @extend %is-error;
    }
  }
  ._form-name {
    input {
      width:350px;
    }
  }
  ._form-mail,
  ._form-mail-confirmation {
    input {
      width:500px;
    }
  }
  ._form-message {
    dt {
      vertical-align:top;
    }
    dd {
      padding-bottom: 0;
      textarea {
        @include fz(14);
        width:500px;
        height:300px;
        border: 1px solid #CCCCCC;
        &.is-error {
          @extend %is-error;
        }

      }
    }
  }
  @include mqSP {
    width:100%;
    dl {
      display: block;
    }
    dt {
      text-align: left;
    }
    dt,dd {
      display: block;
      @include fz(14);
      padding:10px 0px 0 0;
    }
    ._form-name {
      input {
        width:100%;
      }
    }
    ._form-mail,
    ._form-mail-confirmation {
      input {
        width:100%;
      }
    }
    ._form-message {
      dd {
        textarea {
          width:100%;
          height:150px;
        }
      }
    }
  }
}

.form-btn {
  width: 100%;
  margin: 40px 0 20px 0;
  .button {
    position: relative;
    font-size: 20px;
    display: block;
    margin: 0 auto;
    outline: none;
    border: none;
    font-family: $f_min;
    padding-right: 0;
    letter-spacing: .02em;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  @include mqSP {
    margin: 20px 0;
    .button {
      width: 100%;
      font-size: 16px;
    }
  }
}

.tel-section {
  background-color: rgba(255,255,255,0.9);
  box-shadow: 0px 0px 5px 2px rgba(154,154,154,0.2);
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  &__header {
    margin-bottom: 24px;
    text-align: center;
    ._heading {
      @include fz(22);
      font-family: $font-family-second;
    }
  }
  &__body {
    text-align: center;
    .top-inquiry-section-tel {
      margin-bottom: 20px;
    }
  }
  @include mqSP {
    padding:30px 20px;
    width: calc(100% - 20px);
    &__header {
      ._heading {
        @include fz(20);
      }
    }
    &__body {
    }
  }
}
















input:focus,
textarea:focus {
  background: #f1faff;
  box-shadow: 0px 0px 7px #60caff;
  outline: none;
}