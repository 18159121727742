//
// お問い合わせ
//
.l-top-inquiry {
  background: url(../img/background_gray.png) repeat;
  padding: 75px 0;
  @include mqSP {
    padding: 40px 10px;
  }
}
.top-inquiry-inner {
  width: 1070px;
  margin: 0 auto;
  .button {
    margin: 0 auto;
  }
  @include mqSP {
    width: 100%;
  }
}
.top-inquiry-text {
  font-size: 16px;
  line-height: 1.87;
  text-align: center;
  margin: 0 0 50px 0;
  @include mqSP {
    font-size: 12px;
    line-height: 1.66;
    text-align: center;
    margin: 0 0 30px 0;
  }
}
.top-inquiry-section {
  @include clearfix;
  text-align: center;
}
.top-inquiry-tel,
.top-inquiry-form {
  background: #fff;
  width: 526px;
  height: 222px;
  padding-top: 45px;
  box-shadow: 1px 1px 2px rgba(0,0,0,.3);
  @include mqSP {
    width: 100%;
    height: 120px;
    margin: 0 0 15px 0;
    padding-top: 20px;
  }
}
.top-inquiry-tel {
  float: left;
  @include mqSP {
    float: none;
  }
}
.top-inquiry-form {
  float: right;
  @include mqSP {
    float: none;
  }
}

.top-inquiry-section-heading {
  font-size: 26px;
  margin-bottom: 30px;
  @include mqSP {
    font-size: 15px;
    margin-bottom: 15px;
  }
}

.top-inquiry-section-tel {
  margin: 0 0 30px 0;
  a {
    color: $c_blue;
    font-size: 34px;
    text-decoration: none;
    &:before {
      content: "";
      display: inline-block;
      position: relative;
      top: 3px;
      width: 26px;
      height: 31px;
      padding: 0 10px 0 0;
      background-image: url(../img/icon_tel.png);
      background-repeat: no-repeat;
      background-size: 26px auto;
      background-position: 0 0;
    }
  }
  @include mqSP {
    margin: 0 0 15px 0;
    a {
      font-size: 25px;
      &:before {
        top: 2px;
        width: 17px;
        height: 22px;
        background-size: 17px auto;
      }
    }
  }
}
.top-inquiry-section-time {
  font-size: 15px;
  color: #808080;
  @include mqSP {
    font-size: 9px;
  }
 }