@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  /*  font-weight: normal;*/
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ul, ol {
  list-style: none; }

a {
  margin: 0;
  padding: 0;
  outline: none;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input, select {
  vertical-align: middle; }

input[type="submit"],
input[type="reset"],
button,
select {
  cursor: pointer;
  -webkit-appearance: none;
  outline: none; }

img {
  vertical-align: bottom;
  outline: none;
  outline: 0; }

button {
  border: none;
  outline: none;
  margin: 0;
  padding: 0; }

address {
  font-style: normal; }

/*---------------------------------------------------------*/
/* ベース */
/*---------------------------------------------------------*/
html, body {
  width: 100%;
  height: 100%; }

html {
  font-size: 62.5%; }

body {
  position: relative;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  color: #323232;
  font-size: 14px;
  background: url(../img/background.png) repeat; }

* {
  box-sizing: border-box; }

a {
  color: inherit;
  text-decoration: underline; }
  a:hover {
    text-decoration: none;
    opacity: 0.7; }

img {
  vertical-align: bottom; }

small {
  font-size: 11px;
  font-size: 1.1rem; }

.l-breadcrumb {
  width: 1070px;
  margin: 0 auto 80px auto;
  padding: 15px 0; }
  .l-breadcrumb ul li {
    display: inline;
    font-size: 13px;
    color: #4c4c4c; }
    .l-breadcrumb ul li:after {
      content: "";
      display: inline-block;
      width: 6px;
      height: 11px;
      margin: 0 10px;
      background-image: url(../img/breadcrumb-arrow.png);
      background-repeat: no-repeat; }
    .l-breadcrumb ul li:last-child:after {
      display: none; }
    .l-breadcrumb ul li a {
      text-decoration: underline; }
  @media screen and (max-width: 767px) {
    .l-breadcrumb {
      width: 100%;
      padding: 15px 10px;
      margin-bottom: 20px; }
      .l-breadcrumb ul li {
        font-size: 10px; }
        .l-breadcrumb ul li:after {
          width: 3px;
          height: 6px;
          margin: 0 10px;
          background-size: 3px auto; } }

.button, .button-top-inquiry {
  position: relative;
  background: #00367e;
  color: #fff;
  display: block;
  text-decoration: none;
  height: 44px;
  line-height: 44px;
  width: 240px;
  text-align: center;
  padding-right: 10px; }
  .button:after, .button-top-inquiry:after {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    right: 10px;
    width: 5px;
    height: 11px;
    background-image: url(../img/button-arrow.png);
    background-repeat: no-repeat;
    background-size: 5px auto;
    background-position: 0 0; }
  @media screen and (max-width: 767px) {
    .button, .button-top-inquiry {
      margin-right: 5px; } }

.button-top-inquiry {
  padding-right: 0;
  font-size: 20px;
  text-shadow: 0px 1px 0px rgba(7, 0, 2, 0.2);
  width: 284px;
  height: 50px;
  line-height: 50px;
  letter-spacing: 0em; }
  .button-top-inquiry:after {
    display: none; }
  .button-top-inquiry span:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 2px;
    width: 29px;
    height: 19px;
    padding: 0 5px 0 0;
    background-image: url(../img/icon_mail.png);
    background-repeat: no-repeat;
    background-size: 29px auto;
    background-position: 0 0; }
  @media screen and (max-width: 767px) {
    .button-top-inquiry {
      width: 240px;
      height: 42px;
      line-height: 42px;
      font-size: 17px; }
      .button-top-inquiry span:before {
        width: 24px;
        height: 16px;
        background-size: 24px auto; } }

.contact-txt {
  text-align: center;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 40px; }
  .contact-txt:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .contact-txt {
      font-size: 13px;
      margin-bottom: 25px;
      padding: 0 10px; }
      .contact-txt br {
        display: none; } }

.form-section {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 5px 2px rgba(154, 154, 154, 0.2);
  width: 100%;
  margin: 0 auto 35px auto;
  padding: 55px; }
  .form-section__header {
    margin-bottom: 35px;
    text-align: center; }
    .form-section__header ._heading {
      font-size: 30px;
      font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif; }
  .form-section__body ._txt {
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 50px; }
    .form-section__body ._txt:last-child {
      margin-bottom: 0;
      color: #808080;
      font-size: 15px;
      line-height: 1.466; }
    .form-section__body ._txt a {
      text-decoration: underline; }
  .form-section__body sup {
    font-size: 5px;
    vertical-align: super; }
  @media screen and (max-width: 767px) {
    .form-section {
      padding: 30px 20px;
      width: calc(100% - 20px);
      margin: 0 auto 20px auto; }
      .form-section__header {
        margin-bottom: 25px; }
        .form-section__header ._heading {
          font-size: 20px;
          letter-spacing: -.01em; }
      .form-section__body ._txt {
        font-size: 12px;
        line-height: 22px;
        text-align: left;
        margin-bottom: 20px; }
        .form-section__body ._txt:last-child {
          font-size: 12px; }
      .form-section__body sup {
        font-size: 5px; } }

.form-cont input.is-error, .form-cont ._form-message dd textarea.is-error {
  background-color: #fff7f8;
  background-image: url(../img/icon_error.png);
  background-repeat: no-repeat;
  background-size: 18px auto;
  background-position: 98% center;
  border: 1px solid #cd2929; }

.form-cont {
  display: table;
  width: 780px;
  margin: 0 auto; }
  .form-cont .error {
    display: block;
    font-size: 13px;
    padding-top: 5px;
    color: red; }
  .form-cont dl {
    display: table-row; }
  .form-cont dt, .form-cont dd {
    display: table-cell;
    font-size: 16px;
    padding: 15px; }
  .form-cont dt {
    text-align: right; }
  .form-cont input {
    font-size: 14px;
    padding: 10px;
    border: 1px solid #CCCCCC; }
  .form-cont ._form-name input {
    width: 350px; }
  .form-cont ._form-mail input,
  .form-cont ._form-mail-confirmation input {
    width: 500px; }
  .form-cont ._form-message dt {
    vertical-align: top; }
  .form-cont ._form-message dd {
    padding-bottom: 0; }
    .form-cont ._form-message dd textarea {
      font-size: 14px;
      width: 500px;
      height: 300px;
      border: 1px solid #CCCCCC; }
  @media screen and (max-width: 767px) {
    .form-cont {
      width: 100%; }
      .form-cont dl {
        display: block; }
      .form-cont dt {
        text-align: left; }
      .form-cont dt, .form-cont dd {
        display: block;
        font-size: 14px;
        padding: 10px 0px 0 0; }
      .form-cont ._form-name input {
        width: 100%; }
      .form-cont ._form-mail input,
      .form-cont ._form-mail-confirmation input {
        width: 100%; }
      .form-cont ._form-message dd textarea {
        width: 100%;
        height: 150px; } }

.form-btn {
  width: 100%;
  margin: 40px 0 20px 0; }
  .form-btn .button, .form-btn .button-top-inquiry {
    position: relative;
    font-size: 20px;
    display: block;
    margin: 0 auto;
    outline: none;
    border: none;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
    padding-right: 0;
    letter-spacing: .02em; }
    .form-btn .button input, .form-btn .button-top-inquiry input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0; }
  @media screen and (max-width: 767px) {
    .form-btn {
      margin: 20px 0; }
      .form-btn .button, .form-btn .button-top-inquiry {
        width: 100%;
        font-size: 16px; } }

.tel-section {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 5px 2px rgba(154, 154, 154, 0.2);
  width: 100%;
  margin: 0 auto;
  padding: 30px; }
  .tel-section__header {
    margin-bottom: 24px;
    text-align: center; }
    .tel-section__header ._heading {
      font-size: 22px;
      font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif; }
  .tel-section__body {
    text-align: center; }
    .tel-section__body .top-inquiry-section-tel {
      margin-bottom: 20px; }
  @media screen and (max-width: 767px) {
    .tel-section {
      padding: 30px 20px;
      width: calc(100% - 20px); }
      .tel-section__header ._heading {
        font-size: 20px; } }

input:focus,
textarea:focus {
  background: #f1faff;
  box-shadow: 0px 0px 7px #60caff;
  outline: none; }

.l-footer {
  width: 100%;
  min-width: 1090px;
  background: #00367e;
  color: #fff;
  font-size: 13px;
  line-height: 2.15; }
  @media screen and (max-width: 767px) {
    .l-footer {
      min-width: 100%;
      font-size: 9px;
      line-height: 1.8; } }

.footer-inner {
  *zoom: 1;
  width: 1070px;
  margin: 0 auto;
  padding: 70px 0 100px 0; }
  .footer-inner:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (max-width: 767px) {
    .footer-inner {
      width: 100%;
      padding: 0; } }

.footer-heading {
  font-size: 15px;
  margin-bottom: 25px;
  line-height: 1; }
  @media screen and (max-width: 767px) {
    .footer-heading {
      font-size: 11px; } }

.footer-address,
.footer-map,
.footer-nav {
  width: calc(100% / 3);
  float: left; }
  @media screen and (max-width: 767px) {
    .footer-address,
    .footer-map,
    .footer-nav {
      float: none;
      width: 100%;
      border-bottom: 1px solid #808080; } }

@media screen and (max-width: 767px) {
  .footer-address {
    padding: 20px 10px 30px; } }

@media screen and (max-width: 767px) {
  .footer-map {
    padding: 25px 10px; } }

.footer-gmap {
  width: 297px;
  height: 200px;
  background: #ccc; }
  @media screen and (max-width: 767px) {
    .footer-gmap {
      width: 100%; } }

.footer-map-text {
  width: 297px;
  margin: 10px 0 0 0;
  text-align: right; }
  .footer-map-text a {
    font-size: 11px; }
  @media screen and (max-width: 767px) {
    .footer-map-text {
      width: 100%; }
      .footer-map-text a {
        font-size: 9px; } }

@media screen and (max-width: 767px) {
  .footer-nav {
    padding: 25px 10px; } }

.footer-copy {
  border-top: 1px solid #a4a4a4; }
  .footer-copy p {
    width: 1070px;
    margin: 0 auto;
    padding: 25px 0;
    color: #b3b3b3;
    font-size: 11px; }
  @media screen and (max-width: 767px) {
    .footer-copy {
      border: none; }
      .footer-copy p {
        width: 100%;
        padding: 20px 10px;
        text-align: center;
        font-size: 9px; } }

.l-header {
  width: 100%;
  min-width: 1090px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 7px 0px rgba(7, 0, 2, 0.6);
  border-top: 7px solid #00367e;
  box-sizing: border-box; }
  @media screen and (max-width: 767px) {
    .l-header {
      border-top: 4px solid #00367e;
      min-width: 100%; } }

.header-inner {
  position: relative;
  width: 1070px;
  height: 120px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .header-inner {
      width: 100%;
      height: 55px; } }

.header-logo {
  position: absolute;
  left: 0;
  top: calc(50% - 26px);
  width: 167px;
  height: 53px;
  background-image: url("../img/logo.png");
  background-repeat: no-repeat;
  background-size: 167px auto;
  background-repeat: no-repeat;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap; }
  .header-logo a {
    display: block;
    width: 167px;
    height: 53px; }
  @media screen and (max-width: 767px) {
    .header-logo {
      left: calc(50% - 53px);
      top: calc(50% - 16px);
      width: 106px;
      height: 33px;
      background-size: 106px auto; } }

.header-tel {
  position: absolute;
  top: 25px;
  right: 225px; }
  .header-tel a {
    color: #00367e;
    font-size: 29px;
    text-decoration: none; }
    .header-tel a span:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 24px;
      padding: 0 7px 0 0;
      background-image: url("../img/icon_tel.png");
      background-repeat: no-repeat;
      background-size: 20px auto; }
  @media screen and (max-width: 767px) {
    .header-tel {
      display: none; } }

.header-contact {
  position: absolute;
  top: 20px;
  right: 0; }
  .header-contact a {
    display: block;
    background: #00367e;
    color: #d2b93a;
    width: 210px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    text-decoration: none; }
    .header-contact a span:before {
      content: "";
      display: inline-block;
      position: relative;
      top: 2px;
      width: 18px;
      height: 14px;
      padding: 0 7px 0 0;
      background-image: url("../img/icon_mail_header.png");
      background-repeat: no-repeat;
      background-size: 18px auto; }
  @media screen and (max-width: 767px) {
    .header-contact {
      display: none; } }

.header-nav {
  position: absolute;
  right: 0;
  bottom: 20px; }
  .header-nav ul {
    *zoom: 1; }
    .header-nav ul:after {
      content: "";
      display: table;
      clear: both; }
    .header-nav ul li {
      float: left;
      margin: 0 0 0 35px; }
      .header-nav ul li a {
        text-decoration: none;
        padding-bottom: 8px; }
        .header-nav ul li a.is-current {
          border-bottom: 2px solid #d2b93a; }
  @media screen and (max-width: 767px) {
    .header-nav {
      bottom: initial;
      top: 55px;
      z-index: 9999;
      width: 100%;
      height: 0;
      background-color: rgba(0, 54, 126, 0.9);
      transition: height .3s ease;
      overflow: hidden; }
      .header-nav ul {
        width: 100%; }
        .header-nav ul li {
          float: none;
          width: 100%;
          margin: 0; }
          .header-nav ul li a {
            display: block;
            color: #fff;
            height: 40px;
            padding: 0 10px;
            line-height: 40px;
            font-size: 15px;
            border-bottom: 1px solid rgba(210, 185, 58, 0.5); }
            .header-nav ul li a.is-current {
              border-bottom: 1px solid rgba(210, 185, 58, 0.5); }
      body.nav-open .header-nav {
        height: 200px; } }

.header-hamburger {
  display: none; }
  @media screen and (max-width: 767px) {
    .header-hamburger {
      display: block;
      position: absolute;
      right: 10px;
      top: 31px;
      width: 30px;
      text-align: center; }
      .header-hamburger a {
        display: block;
        width: 30px;
        height: 0px;
        border-top: 3px solid #00367e;
        color: #00367e;
        transition: border-color .3s ease; }
        .header-hamburger a:before, .header-hamburger a:after {
          content: "";
          display: block;
          position: absolute;
          width: 30px;
          height: 3px;
          background: #00367e;
          transition: transform .3s ease; }
        .header-hamburger a:before {
          bottom: -8px; }
        .header-hamburger a:after {
          top: -8px; }
        .header-hamburger a span {
          display: block;
          position: absolute;
          top: -21px;
          left: 0;
          font-size: 8px;
          font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Arial, Helvetica, sans-serif;
          font-weight: bold;
          letter-spacing: -.05em; }
        body.nav-open .header-hamburger a {
          border-color: transparent; }
          body.nav-open .header-hamburger a:before {
            transform: translateY(-7px) rotate(45deg); }
          body.nav-open .header-hamburger a:after {
            transform: translateY(9px) rotate(-45deg); } }

.section-header {
  text-align: center; }

.section-heading {
  margin: 0 0 40px 0;
  padding: 0 40px 10px 40px;
  color: #00367e;
  font-size: 36px;
  display: inline-block;
  border-bottom: 1px solid #00367e; }
  .section-heading span {
    font-size: 26px; }
  @media screen and (max-width: 767px) {
    .section-heading {
      font-size: 21px;
      display: block;
      width: 100%;
      margin: 0 0 20px 0;
      padding: 0 0 10px 0;
      line-height: 1.14; }
      .section-heading span {
        font-size: 16px; } }

.page-heading {
  font-size: 36px;
  line-height: 1.27;
  color: #00367e;
  border-bottom: 1px solid #00367e;
  padding: 0 0 15px 0;
  margin: 0 0 50px 0; }
  @media screen and (max-width: 767px) {
    .page-heading {
      font-size: 21px;
      padding: 0 0 10px 0;
      margin: 0 auto 20px auto;
      width: calc(100% - 20px); } }

.page-heading_sub {
  font-size: 26px;
  color: #2c77db;
  line-height: 1.7;
  border-left: 4px solid #2c77db;
  padding: 0 0 0 15px;
  margin: 0 0 25px 0; }
  @media screen and (max-width: 767px) {
    .page-heading_sub {
      margin: 0 10px 20px 10px;
      font-size: 17px; } }

.l-top-kv {
  position: relative;
  top: 0px;
  z-index: 0;
  width: 100%;
  height: 490px;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .l-top-kv {
      height: 320px; } }

.top-kv-inner {
  width: 100%;
  height: 490px; }
  @media screen and (max-width: 767px) {
    .top-kv-inner {
      height: 320px; } }

.top-kv-slide {
  width: 100%;
  height: 490px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media screen and (max-width: 767px) {
    .top-kv-slide {
      height: 320px; } }
  .top-kv-slide.slide01 {
    background-image: url(../img/kv01.png); }
    @media screen and (max-width: 767px) {
      .top-kv-slide.slide01 {
        background-image: url(../img/kv01_sp.png); } }
  .top-kv-slide.slide02 {
    background-image: url(../img/kv02.png); }
    @media screen and (max-width: 767px) {
      .top-kv-slide.slide02 {
        background-image: url(../img/kv02_sp.png); } }
  .top-kv-slide.slide03 {
    background-image: url(../img/kv03.png); }
    @media screen and (max-width: 767px) {
      .top-kv-slide.slide03 {
        background-image: url(../img/kv03_sp.png); } }
  .top-kv-slide.slide04 {
    background-image: url(../img/kv04.png); }
    @media screen and (max-width: 767px) {
      .top-kv-slide.slide04 {
        background-image: url(../img/kv04_sp.png); } }

.top-kv-copy {
  position: absolute;
  bottom: 75px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 42px;
  color: #fff;
  text-shadow: 0px 0px 4.5px rgba(7, 0, 2, 0.74); }
  .top-kv-copy span {
    display: block;
    margin: 0 0 10px 0;
    font-size: 29px; }
  @media screen and (max-width: 767px) {
    .top-kv-copy {
      font-size: 27px;
      line-height: 1.15;
      bottom: 50px;
      letter-spacing: 0; }
      .top-kv-copy span {
        margin-bottom: 5px;
        font-size: 20px; } }

.list-check {
  *zoom: 1; }
  .list-check:after {
    content: "";
    display: table;
    clear: both; }
  .list-check li {
    float: left;
    font-size: 20px;
    margin: 0 25px 0 0; }
    .list-check li:before {
      content: "";
      display: inline-block;
      width: 23px;
      height: 20px;
      position: relative;
      top: 2px;
      margin: 0 5px 0 0;
      background-image: url(../img/icon_check.png);
      background-repeat: no-repeat; }
  @media screen and (max-width: 767px) {
    .list-check li {
      font-size: 16px;
      float: none;
      margin: 0 0 5px 0; }
      .list-check li:before {
        width: 15px;
        height: 15px;
        background-size: 15px auto; } }

.list-dot {
  line-height: 1.87;
  font-size: 16px;
  margin: 10px 0 0 0; }
  .list-dot li {
    position: relative;
    padding: 0 0 0 15px; }
    .list-dot li:before {
      content: "・";
      position: absolute;
      left: 0;
      top: 0; }
  @media screen and (max-width: 767px) {
    .list-dot {
      font-size: 12px;
      padding: 0 10px; } }

@media screen and (max-width: 767px) {
  .l-news {
    padding: 0 10px; } }

.news-date {
  font-size: 20px;
  margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    .news-date {
      font-size: 16px;
      margin-bottom: 20px; } }

.news-heading {
  position: relative;
  margin: 0 0 50px 0;
  padding: 0 0 30px 0;
  font-size: 32px;
  line-height: 1.3125; }
  .news-heading:before, .news-heading:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    background: #000; }
  .news-heading:before {
    height: 2px;
    bottom: 0; }
  .news-heading:after {
    height: 1px;
    bottom: -3px; }
  @media screen and (max-width: 767px) {
    .news-heading {
      font-size: 21px;
      margin: 0 auto 20px;
      padding: 0 0 20px 0; } }

.news-body {
  font-size: 18px;
  line-height: 2; }
  .news-body p {
    margin: 0 0 50px 0; }
    .news-body p:empty {
      margin: 0;
      line-height: 0;
      height: 0;
      font-size: 0; }
  .news-body ul, .news-body ol {
    margin: 0 0 50px 0;
    padding: 0 0 0 25px; }
    .news-body ul li, .news-body ol li {
      margin: 0 0 10px 0;
      font-size: 16px; }
      .news-body ul li:last-child, .news-body ol li:last-child {
        margin-bottom: 0; }
  .news-body ul {
    list-style: disc; }
  .news-body ol {
    list-style: decimal; }
  @media screen and (max-width: 767px) {
    .news-body {
      font-size: 16px;
      line-height: 1.6; }
      .news-body p, .news-body ul, .news-body ol {
        margin-bottom: 25px; } }

.news-notes {
  background: #f0f0f0;
  color: #666;
  padding: 25px;
  font-size: 16px;
  line-height: 1.62;
  margin-top: 50px; }
  .news-notes .news-note-heading {
    font-weight: bold;
    margin-bottom: 0; }
  .news-notes p {
    margin-bottom: 15px; }
    .news-notes p:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .news-notes {
      margin-top: 20px;
      padding: 15px;
      font-size: 14px; }
      .news-notes .news-note-heading {
        margin: 0 0 10px 0; } }

.l-page-header {
  position: relative;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center; }
  .l-page-header.page-header_vision {
    background-image: url(../img/vision-heading.png); }
  .l-page-header.page-header_oem {
    background-image: url(../img/oem-heading.png); }
  .l-page-header.page-header_products {
    background-image: url(../img/products-heading.png); }
  .l-page-header.page-header_policy {
    background-image: url(../img/policy-heading.png); }
  .l-page-header.page-header_news {
    background-image: url(../img/news-heading.png); }
  .l-page-header span {
    display: block;
    text-align: center;
    color: #fff;
    width: 100%;
    text-shadow: 0px 0px 4.65px rgba(7, 0, 2, 0.75);
    font-size: 36px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    letter-spacing: .08em; }
  .l-page-header:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #00367e;
    opacity: .7; }
  @media screen and (max-width: 767px) {
    .l-page-header {
      height: 100px; }
      .l-page-header span {
        font-size: 21px; } }

.page-bg {
  background-image: url(../img/background_page.png);
  background-repeat: repeat-y;
  background-position: 0 -440px; }
  @media screen and (max-width: 767px) {
    .page-bg {
      background-size: 100% auto;
      background-position: 0 0; } }

.page-header {
  width: 930px;
  margin: 0 auto 70px auto; }
  @media screen and (max-width: 767px) {
    .page-header {
      width: 100%;
      margin-bottom: 40px; } }

.page-box {
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #e6e6e6;
  padding: 30px;
  margin: 0 0 45px 0; }
  @media screen and (max-width: 767px) {
    .page-box {
      padding: 20px;
      margin: 0 10px 20px 10px; } }

.page-box-heading {
  font-size: 21px;
  color: #00367e;
  margin: 0 0 20px 0; }
  @media screen and (max-width: 767px) {
    .page-box-heading {
      font-size: 17px;
      margin: 0 0 20px 0; } }

.page-1col,
.page-2col,
.page-2col_sub {
  width: 930px;
  margin: 0 auto 100px auto; }
  .page-1col:last-of-type,
  .page-2col:last-of-type,
  .page-2col_sub:last-of-type {
    padding-bottom: 150px;
    margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .page-1col,
    .page-2col,
    .page-2col_sub {
      width: 100%;
      margin: 0 0 40px 0; }
      .page-1col:last-of-type,
      .page-2col:last-of-type,
      .page-2col_sub:last-of-type {
        margin-bottom: 50px; } }

.page-text, .page-note, .notfound-text {
  font-size: 16px;
  line-height: 1.87;
  margin: 0 0 40px 0; }
  .page-text:last-of-type, .page-note:last-of-type, .notfound-text:last-of-type {
    margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .page-text, .page-note, .notfound-text {
      font-size: 14px;
      margin: 0 0 20px 0;
      padding: 0 10px; } }

.page-note {
  color: #555555;
  font-size: 13px; }
  @media screen and (max-width: 767px) {
    .page-note {
      font-size: 12px; } }

.page-pic {
  margin: 60px 0 0 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  display: block; }
  @media screen and (max-width: 767px) {
    .page-pic {
      margin: 20px 0 0 0;
      width: 100%; }
      .page-pic img {
        max-width: 100%; } }

.page-2col .page-inner, .page-2col_sub .page-inner {
  *zoom: 1; }
  .page-2col .page-inner:after, .page-2col_sub .page-inner:after {
    content: "";
    display: table;
    clear: both; }
  .page-2col .page-inner .page-left, .page-2col_sub .page-inner .page-left, .page-2col .page-inner .page-left-large, .page-2col_sub .page-inner .page-left-large {
    float: left; }
  .page-2col .page-inner .page-right, .page-2col_sub .page-inner .page-right, .page-2col .page-inner .page-right-large, .page-2col_sub .page-inner .page-right-large {
    float: right; }
  .page-2col .page-inner .page-text, .page-2col_sub .page-inner .page-text, .page-2col .page-inner .page-note, .page-2col_sub .page-inner .page-note, .page-2col .page-inner .notfound-text, .page-2col_sub .page-inner .notfound-text {
    width: 450px;
    max-width: 100%; }
  .page-2col .page-inner .page-right-large .page-text, .page-2col_sub .page-inner .page-right-large .page-text, .page-2col .page-inner .page-right-large .page-note, .page-2col_sub .page-inner .page-right-large .page-note, .page-2col .page-inner .page-right-large .notfound-text, .page-2col_sub .page-inner .page-right-large .notfound-text {
    width: 530px; }
  .page-2col .page-inner .page-left-large .page-text, .page-2col_sub .page-inner .page-left-large .page-text, .page-2col .page-inner .page-left-large .page-note, .page-2col_sub .page-inner .page-left-large .page-note, .page-2col .page-inner .page-left-large .notfound-text, .page-2col_sub .page-inner .page-left-large .notfound-text {
    width: 530px; }
  .page-2col .page-inner .page-pic, .page-2col_sub .page-inner .page-pic {
    margin: 0; }

@media screen and (max-width: 767px) {
  .page-2col .page-inner .page-left, .page-2col_sub .page-inner .page-left, .page-2col .page-inner .page-left-large, .page-2col_sub .page-inner .page-left-large {
    float: none; }
  .page-2col .page-inner .page-right, .page-2col_sub .page-inner .page-right, .page-2col .page-inner .page-right-large, .page-2col_sub .page-inner .page-right-large {
    float: none; }
  .page-2col .page-inner .page-text, .page-2col_sub .page-inner .page-text, .page-2col .page-inner .page-note, .page-2col_sub .page-inner .page-note, .page-2col .page-inner .notfound-text, .page-2col_sub .page-inner .notfound-text {
    width: 100% !important; }
  .page-2col .page-inner .page-pic, .page-2col_sub .page-inner .page-pic {
    margin: 20px 0 0 0; } }

.page-2col_sub {
  margin-bottom: 75px; }
  .page-2col_sub .page-left, .page-2col .page-inner .page-2col_sub .page-left-large, .page-2col_sub .page-inner .page-left-large {
    width: 370px; }
  .page-2col_sub .page-right, .page-2col .page-inner .page-2col_sub .page-right-large, .page-2col_sub .page-inner .page-right-large {
    width: 500px; }
  .page-2col_sub .page-text, .page-2col_sub .page-note, .page-2col_sub .notfound-text {
    width: 100%; }
  @media screen and (max-width: 767px) {
    .page-2col_sub {
      margin-bottom: 40px; }
      .page-2col_sub .page-left, .page-2col .page-inner .page-2col_sub .page-left-large, .page-2col_sub .page-inner .page-left-large {
        width: 100%; }
        .page-2col_sub .page-left .button, .page-2col .page-inner .page-2col_sub .page-left-large .button, .page-2col_sub .page-inner .page-left-large .button, .page-2col_sub .page-left .button-top-inquiry, .page-2col .page-inner .page-2col_sub .page-left-large .button-top-inquiry, .page-2col_sub .page-inner .page-left-large .button-top-inquiry {
          margin: 0 auto; }
      .page-2col_sub .page-right, .page-2col .page-inner .page-2col_sub .page-right-large, .page-2col_sub .page-inner .page-right-large {
        width: 100%; } }

.page-note {
  margin-top: -20px;
  font-size: 13px;
  color: #666; }
  @media screen and (max-width: 767px) {
    .page-note {
      margin-top: 0px;
      font-size: 11px; } }

.page-nav {
  *zoom: 1;
  width: 930px;
  margin: 0 auto;
  padding-bottom: 100px;
  font-size: 16px; }
  .page-nav:after {
    content: "";
    display: table;
    clear: both; }
  .page-nav p:first-child {
    float: left; }
  .page-nav p:last-child {
    float: right; }
  .page-nav p a {
    display: block; }
  @media screen and (max-width: 767px) {
    .page-nav {
      width: 100%;
      padding: 0 10px;
      margin: 0 0 40px 0;
      font-size: 12px; }
      .page-nav p {
        margin-bottom: 10px;
        width: 90%;
        line-height: 1.7; }
        .page-nav p:first-child {
          text-align: left; }
        .page-nav p:last-child {
          text-align: right; }
        .page-nav p a {
          padding: 5px 0px; } }

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  text-align: center;
  font-weight: normal;
  line-height: 15px; }
  @media screen and (max-width: 767px) {
    .pagetop {
      position: fixed;
      right: 10px;
      bottom: 10px;
      text-align: center;
      font-weight: normal;
      line-height: 15px; } }

.pagetop a {
  display: block;
  border-radius: 6px;
  background-color: #333333;
  opacity: 0.8;
  width: 76px;
  height: 76px;
  color: #fff;
  font-size: 10px;
  text-decoration: none;
  padding: 44px 0 0 0; }
  .pagetop a:before {
    content: "";
    display: block;
    position: absolute;
    top: 25px;
    left: calc(50% - 10px);
    width: 20px;
    height: 20px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(-45deg); }
  @media screen and (max-width: 767px) {
    .pagetop a {
      display: table-cell;
      vertical-align: middle;
      border-radius: 6px;
      background-color: #333333;
      opacity: 0.8;
      width: 80px;
      height: 80px;
      padding-top: 35px;
      font-size: 8px;
      transform: scale(0.7); }
      .pagetop a:before {
        top: 25px; } }

.policy-heading {
  font-size: 18px;
  font-weight: bold;
  margin: 40px 0 10px 0; }
  @media screen and (max-width: 767px) {
    .policy-heading {
      font-size: 14px;
      padding: 0 10px;
      margin: 30px 0 10px 0; } }

.policy-text {
  line-height: 1.87;
  font-size: 16px; }
  @media screen and (max-width: 767px) {
    .policy-text {
      font-size: 12px;
      padding: 0 10px; } }

.products-header {
  width: 1070px;
  text-align: center;
  line-height: 1.87;
  font-size: 16px;
  margin: 0 auto 40px auto; }
  @media screen and (max-width: 767px) {
    .products-header {
      width: 100%;
      font-size: 14px;
      text-align: left;
      padding: 0 10px; } }

.products-recommend {
  background: url(../img/background_products.png) repeat;
  padding: 60px 0 70px 0; }
  @media screen and (max-width: 767px) {
    .products-recommend {
      padding: 20px 0 10px 0; } }

.products-section {
  padding: 60px 0 70px 0; }
  @media screen and (max-width: 767px) {
    .products-section {
      padding: 20px 0 10px 0; } }

.products-inner {
  width: 1070px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .products-inner {
      padding: 0 10px;
      width: 100%; } }

.products-heading {
  margin: 0 0 30px 0;
  font-size: 36px;
  text-align: center; }
  .products-recommend .products-heading {
    color: #7c400c; }
  .products-section .products-heading {
    color: #00367e; }
  @media screen and (max-width: 767px) {
    .products-heading {
      font-size: 22px;
      margin: 0 0 20px 0; } }

.products-list {
  display: flex;
  flex-wrap: wrap; }
  .products-list li {
    width: calc(25% - 3px);
    background: #fff;
    margin-right: 4px;
    margin-top: 40px; }
    .products-list li:nth-child(4n) {
      margin-right: 0; }
    .products-list li:nth-child(1), .products-list li:nth-child(2), .products-list li:nth-child(3), .products-list li:nth-child(4) {
      margin-top: 0; }
    .products-list li dl dt {
      line-height: 0; }
      .products-list li dl dt img {
        width: 100%;
        vertical-align: top; }
    .products-list li dl dd {
      padding: 20px; }
    .products-list li a {
      display: block;
      text-decoration: none; }
  @media screen and (max-width: 767px) {
    .products-list li {
      width: calc(50% - 2.5px);
      margin-right: 5px;
      margin-top: 5px; }
      .products-list li:nth-child(2n) {
        margin-right: 0; }
      .products-list li:nth-child(3), .products-list li:nth-child(4) {
        margin-top: 5px; }
      .products-list li dl dd {
        padding: 15px 10px; } }

.product-name {
  text-align: center;
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
  font-weight: bold;
  margin: 0 0 10px 0; }
  @media screen and (max-width: 767px) {
    .product-name {
      font-size: 14px; } }

.product-text {
  font-size: 13px;
  line-height: 1.52;
  text-align: center; }
  .products-recommend .product-text {
    text-align: left; }
  @media screen and (max-width: 767px) {
    .product-text {
      font-size: 12px; } }

.bg-gray {
  background: url(../img/background_gray.png) repeat; }

.products-shop {
  width: 1070px;
  margin: 0 auto;
  text-align: center;
  padding: 70px 0; }
  .products-shop ul li {
    margin-bottom: 40px; }
    .products-shop ul li:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .products-shop {
      width: 100%;
      padding: 40px 20px 60px 20px; } }

.products-shop-heading {
  font-size: 24px;
  text-align: center;
  margin: 0 0 40px 0; }
  @media screen and (max-width: 767px) {
    .products-shop-heading {
      font-size: 18px;
      margin: 0 0 20px 0;
      line-height: 1.4; } }

.products-shop-text {
  font-size: 13px;
  margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .products-shop-text {
      font-size: 12px;
      line-height: 1.87;
      text-align: left;
      margin-bottom: 15px; } }

.link-arrow {
  display: inline;
  font-size: 16px;
  color: #00367e; }
  .link-arrow:before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 11px;
    background-image: url(../img/icon_arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    margin: 0 5px 0 0; }
  @media screen and (max-width: 767px) {
    .link-arrow {
      font-size: 13px; } }

.notfound-text {
  text-align: center; }
  .notfound-text:last-of-type {
    margin-bottom: 40px; }
  @media screen and (max-width: 767px) {
    .notfound-text {
      text-align: left; }
      .notfound-text:last-of-type {
        margin-bottom: 30px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.dot-class {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%; }
  .dot-class li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    border-radius: 50%;
    overflow: hidden;
    text-indent: 100%;
    overflow: hidden;
    word-wrap: initial;
    font-size: 0;
    border: 1px solid #fff;
    cursor: pointer; }
    .dot-class li.slick-active {
      background: #fff; }
    .dot-class li button {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%; }

.slick-arrow {
  position: absolute;
  z-index: 999;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  width: 30px;
  height: 30px;
  /* background-color: white; */
  transform-origin: center center;
  -webkit-transform-origin: top left;
  cursor: pointer;
  background: none;
  text-indent: 100%;
  overflow: hidden;
  word-wrap: initial;
  font-size: 0; }
  @media screen and (max-width: 767px) {
    .slick-arrow {
      opacity: 0; } }

.slick-next {
  right: 3.5%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-bottom: 2px solid white;
  border-right: 2px solid white; }

.slick-prev {
  left: 2.5%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top: 2px solid white;
  border-left: 2px solid white; }

.l-top-inquiry {
  background: url(../img/background_gray.png) repeat;
  padding: 75px 0; }
  @media screen and (max-width: 767px) {
    .l-top-inquiry {
      padding: 40px 10px; } }

.top-inquiry-inner {
  width: 1070px;
  margin: 0 auto; }
  .top-inquiry-inner .button, .top-inquiry-inner .button-top-inquiry {
    margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .top-inquiry-inner {
      width: 100%; } }

.top-inquiry-text {
  font-size: 16px;
  line-height: 1.87;
  text-align: center;
  margin: 0 0 50px 0; }
  @media screen and (max-width: 767px) {
    .top-inquiry-text {
      font-size: 12px;
      line-height: 1.66;
      text-align: center;
      margin: 0 0 30px 0; } }

.top-inquiry-section {
  *zoom: 1;
  text-align: center; }
  .top-inquiry-section:after {
    content: "";
    display: table;
    clear: both; }

.top-inquiry-tel,
.top-inquiry-form {
  background: #fff;
  width: 526px;
  height: 222px;
  padding-top: 45px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); }
  @media screen and (max-width: 767px) {
    .top-inquiry-tel,
    .top-inquiry-form {
      width: 100%;
      height: 120px;
      margin: 0 0 15px 0;
      padding-top: 20px; } }

.top-inquiry-tel {
  float: left; }
  @media screen and (max-width: 767px) {
    .top-inquiry-tel {
      float: none; } }

.top-inquiry-form {
  float: right; }
  @media screen and (max-width: 767px) {
    .top-inquiry-form {
      float: none; } }

.top-inquiry-section-heading {
  font-size: 26px;
  margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    .top-inquiry-section-heading {
      font-size: 15px;
      margin-bottom: 15px; } }

.top-inquiry-section-tel {
  margin: 0 0 30px 0; }
  .top-inquiry-section-tel a {
    color: #00367e;
    font-size: 34px;
    text-decoration: none; }
    .top-inquiry-section-tel a:before {
      content: "";
      display: inline-block;
      position: relative;
      top: 3px;
      width: 26px;
      height: 31px;
      padding: 0 10px 0 0;
      background-image: url(../img/icon_tel.png);
      background-repeat: no-repeat;
      background-size: 26px auto;
      background-position: 0 0; }
  @media screen and (max-width: 767px) {
    .top-inquiry-section-tel {
      margin: 0 0 15px 0; }
      .top-inquiry-section-tel a {
        font-size: 25px; }
        .top-inquiry-section-tel a:before {
          top: 2px;
          width: 17px;
          height: 22px;
          background-size: 17px auto; } }

.top-inquiry-section-time {
  font-size: 15px;
  color: #808080; }
  @media screen and (max-width: 767px) {
    .top-inquiry-section-time {
      font-size: 9px; } }

.l-top-news {
  background: url(../img/background_gray.png) repeat;
  padding: 85px 0; }
  @media screen and (max-width: 767px) {
    .l-top-news {
      padding: 40px 0; } }

.top-news-inner {
  width: 930px;
  margin: 0 auto; }
  .top-news-inner .button, .top-news-inner .button-top-inquiry {
    margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .top-news-inner {
      width: 100%;
      padding: 0 10px; } }

.top-news-list {
  width: 820px;
  margin: 0 auto; }
  .top-news-list li {
    margin-bottom: 35px;
    text-align: center; }
    .top-news-list li dl {
      *zoom: 1;
      display: inline-block;
      position: relative; }
      .top-news-list li dl:after {
        content: "";
        display: table;
        clear: both; }
    .top-news-list li:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .top-news-list {
      width: 100%; }
      .top-news-list li {
        margin: 0 0 25px 0; } }

.top-news-list-date {
  font-size: 16px;
  width: 120px;
  float: left;
  position: relative;
  top: 5px; }
  @media screen and (max-width: 767px) {
    .top-news-list-date {
      position: static;
      float: none;
      width: 100%;
      font-size: 11px;
      margin: 0 0 15px 0; } }

.top-news-list-category {
  font-size: 13px;
  background: #333;
  color: #fff;
  width: 110px;
  height: 24px;
  float: left;
  text-align: center;
  margin: 0 0 3px 0;
  padding: 6px 0; }
  @media screen and (max-width: 767px) {
    .top-news-list-category {
      font-size: 9px;
      float: none;
      width: 77px;
      height: 17px;
      position: absolute;
      right: 0;
      top: -5px;
      padding: 4px 0; } }

.top-news-list-title {
  font-size: 16px;
  max-width: 555px;
  float: left;
  line-height: 1.85;
  position: relative;
  top: -4px; }
  @media screen and (max-width: 767px) {
    .top-news-list-title {
      font-size: 12px;
      width: 100%;
      float: none;
      line-height: 1.66; } }

.l-top-oem {
  background-image: url(../img/background_oem.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  padding: 100px 0; }
  @media screen and (max-width: 767px) {
    .l-top-oem {
      background-image: url(../img/background_oem_sp.png);
      padding: 40px 10px; } }

.top-oem-inner {
  width: 1070px;
  margin: 0 auto; }
  .top-oem-inner .button, .top-oem-inner .button-top-inquiry {
    margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .top-oem-inner {
      width: 100%; } }

.top-oem-text {
  font-size: 16px;
  line-height: 1.87;
  text-align: center;
  margin: 0 0 50px 0; }
  @media screen and (max-width: 767px) {
    .top-oem-text {
      font-size: 12px;
      line-height: 1.66;
      text-align: center;
      margin: 0 0 35px 0; } }

.top-oem-achievement {
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  padding: 30px 0;
  margin: 55px 0 0 0; }
  @media screen and (max-width: 767px) {
    .top-oem-achievement {
      padding: 20px 0;
      margin: 35px 0 0 0; } }

.top-oem-achievement-heading {
  font-size: 26px;
  text-align: center;
  color: #00367e;
  margin: 0 0 35px 0; }
  @media screen and (max-width: 767px) {
    .top-oem-achievement-heading {
      font-size: 16px;
      margin: 0 0 20px 0; } }

.top-oem-achievement-title {
  font-size: 36px;
  color: #00367e;
  display: block;
  margin-bottom: 15px; }
  @media screen and (max-width: 767px) {
    .top-oem-achievement-title {
      font-size: 20px;
      margin-bottom: 10px; } }

.top-oem-achievement-text {
  font-size: 16px;
  line-height: 1.5; }
  @media screen and (max-width: 767px) {
    .top-oem-achievement-text {
      font-size: 9px; } }

.top-oem-achievement-list {
  *zoom: 1;
  width: 1012px;
  margin: 0 auto; }
  .top-oem-achievement-list:after {
    content: "";
    display: table;
    clear: both; }
  .top-oem-achievement-list li {
    width: 496px;
    height: 180px;
    float: left;
    border: 1px solid #ccc;
    background-image: url(../img/top-oem-kahaku.png);
    background-size: auto 100%;
    background-position: right center;
    background-repeat: no-repeat; }
    .top-oem-achievement-list li:nth-child(2) {
      float: right;
      background-image: url(../img/top-oem-aoba.png); }
    .top-oem-achievement-list li a {
      display: block;
      text-decoration: none;
      height: 180px;
      width: 100%;
      padding: 25px; }
  @media screen and (max-width: 767px) {
    .top-oem-achievement-list {
      width: 100%;
      padding: 0 10px; }
      .top-oem-achievement-list li {
        width: 100%;
        height: 100px;
        float: none;
        margin: 0 0 10px 0;
        background-image: url(../img/top-oem-kahaku_sp.png); }
        .top-oem-achievement-list li:nth-child(2) {
          float: none;
          background-image: url(../img/top-oem-aoba_sp.png); }
        .top-oem-achievement-list li a {
          padding: 15px; } }

.l-top-section {
  padding: 100px 0 90px; }
  @media screen and (max-width: 767px) {
    .l-top-section {
      padding: 40px 0; } }

.top-section-inner {
  position: relative;
  width: 930px;
  margin: 0 auto;
  overflow: visible; }
  .top-section-inner .section-heading {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    width: 390px; }
  .top-section-inner:after {
    content: "";
    position: absolute; }
  @media screen and (max-width: 767px) {
    .top-section-inner {
      width: 100%;
      padding: 0 10px; }
      .top-section-inner .section-heading {
        text-align: center;
        width: 100%; }
      .top-section-inner:after {
        display: none; }
      .top-section-inner .button, .top-section-inner .button-top-inquiry {
        margin: 0 auto; } }

.top-section-text {
  font-size: 16px;
  line-height: 1.87;
  margin: 0 0 50px 0; }
  @media screen and (max-width: 767px) {
    .top-section-text {
      font-size: 12px;
      line-height: 1.66;
      text-align: center;
      margin: 0 0 35px 0; }
      .top-section-text:after {
        content: "";
        position: relative;
        display: block;
        margin: 30px auto 0 auto;
        width: calc(100% + 20px);
        left: -10px;
        background-repeat: no-repeat; } }

.top-vision .top-section-inner:after {
  right: -70px;
  top: -45px;
  width: 578px;
  height: 338px;
  background-image: url(../img/top-vision.png);
  background-repeat: no-repeat;
  background-position: right center; }

@media screen and (max-width: 767px) {
  .top-vision .top-section-text:after {
    background-image: url(../img/top-vision_sp.png);
    background-size: 100% auto;
    padding: 63% 0 0; } }

.top-products .top-section-inner:after {
  right: -155px;
  top: -55px;
  width: 819px;
  height: 432px;
  background-image: url(../img/top-products.png);
  background-repeat: no-repeat;
  background-position: right center; }

@media screen and (max-width: 767px) {
  .top-products .top-section-text:after {
    background-image: url(../img/top-products_sp.png);
    background-size: 100% auto;
    padding: 67% 0 0; } }

@media screen and (min-width: 415px) {
  .pc-hide {
    display: none; } }

@media screen and (max-width: 767px) {
  .sp-hide {
    display: none !important; } }

.mb50 {
  margin-bottom: 50px !important; }
  @media screen and (max-width: 767px) {
    .mb50 {
      margin-bottom: 25px !important; } }

.mb35 {
  margin-bottom: 35px !important; }
  @media screen and (max-width: 767px) {
    .mb35 {
      margin-bottom: 20px !important; } }

.txtC {
  text-align: center !important; }

.ma {
  margin-left: auto !important;
  margin-right: auto !important; }
