.button {
  position: relative;
  background: $c_blue;
  color: #fff;
  display: block;
  text-decoration: none;
  height: 44px;
  line-height: 44px;
  width: 240px;
  text-align: center;
  padding-right: 10px;
  &:after {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    right: 10px;
    width: 5px;
    height: 11px;
    background-image: url(../img/button-arrow.png);
    background-repeat: no-repeat;
    background-size: 5px auto;
    background-position: 0 0;
  }
  @include mqSP {
    margin-right: 5px;
  }
}

.button-top-inquiry {
  @extend .button;
  padding-right: 0;
  font-size: 20px;
  text-shadow: 0px 1px 0px rgba(7, 0, 2, 0.2);
  width: 284px;
  height: 50px;
  line-height: 50px;
  letter-spacing: 0em;

  &:after {
    display: none;
  }
  span {
    &:before {
      content: "";
      display: inline-block;
      position: relative;
      top: 2px;
      width: 29px;
      height: 19px;
      padding: 0 5px 0 0;
      background-image: url(../img/icon_mail.png);
      background-repeat: no-repeat;
      background-size: 29px auto;
      background-position: 0 0;
    }
  }
  @include mqSP {
    width: 240px;
    height: 42px;
    line-height: 42px;
    font-size: 17px;
    span {
      &:before {
        width: 24px;
        height: 16px;
        background-size: 24px auto;
      }
    }
  }
}