@charset "UTF-8";
//---------------------------------------------------------
// Mixin
//---------------------------------------------------------
//-------------------------
// font-size
//-------------------------
@mixin fz( $val ) {
    font-size: #{$val + 'px'};
}

//-------------------------
// MediaQuery
//-------------------------
@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}





// clearfix
@mixin clearfix {
  *zoom: 1;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// texthidden
@mixin texthidden {
  display: inline-block;
  font-size: 0;
  overflow: hidden;
  vertical-align: middle;
  white-space: nowrap;
  text-indent: 100%;
}

// calc-vw
@function calc-vw($size, $viewport:375){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

// placeholder color
@mixin placeholderColor($color) {
  &:placeholder-shown {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// letter-spacing
@mixin letter-spacing($tracking) {
  letter-spacing: $tracking / 1000 + em;
}

// media query
@mixin mqPC {
  @media screen and (min-width: 415px) {
    @content;
  }
}

@mixin mqSP {
  @media screen and (max-width: 767px) {
    @content;
  }
}

