.l-page {
}
.page-bg {
  background-image: url(../img/background_page.png);
  background-repeat: repeat-y;
  background-position: 0 -440px;
  @include mqSP {
    background-size: 100% auto;
    background-position: 0 0;
  }
}
.page-header {
  width: 930px;
  margin: 0 auto 70px auto;
  @include mqSP {
    width: 100%;
    margin-bottom: 40px;
  }
}
.page-box {
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.5);
  border: 1px solid #e6e6e6;
  padding: 30px;
  margin: 0 0 45px 0;
  @include mqSP {
    padding: 20px;
    margin: 0 10px 20px 10px;
  }
}
.page-box-heading {
  font-size: 21px;
  color: $c_blue;
  margin: 0 0 20px 0;
  @include mqSP {
    font-size: 17px;
    margin: 0 0 20px 0;
  }
}

.page-1col,
.page-2col {
  width: 930px;
  margin: 0 auto 100px auto;
  &:last-of-type {
    padding-bottom: 150px;
    margin-bottom: 0;
  }
  @include mqSP {
    width: 100%;
    margin: 0 0 40px 0;
    &:last-of-type {
      margin-bottom: 50px;
    }
  }
}

.page-text {
  font-size: 16px;
  line-height: 1.87;
  margin: 0 0 40px 0;
  &:last-of-type {
    margin-bottom: 0;
  }
  @include mqSP {
    font-size: 14px;
    margin: 0 0 20px 0;
    padding: 0 10px;
  }
}
.page-note {
  @extend .page-text;
  color: $c_gray;
  font-size: 13px;
  @include mqSP {
    font-size: 12px;
  }
}

.page-pic {
  margin: 60px 0 0 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.5);
  display: block;
  @include mqSP {
    margin: 20px 0 0 0;
    width: 100%;
    img {
      max-width: 100%;
    }
  }
}

.page-2col {
  .page-inner {
    @include clearfix;
    .page-left {
      float: left;
    }
    .page-right {
      float: right;
    }
    .page-text {
      width: 450px;
      max-width: 100%;
    }
    .page-right-large {
      @extend .page-right;
      .page-text {
        width: 530px;
      }
    }
    .page-left-large {
      @extend .page-left;
      .page-text {
        width: 530px;
      }
    }
    .page-pic {
      margin: 0;
    }
  }
  @include mqSP {
    .page-inner {
      .page-left {
        float: none;
      }
      .page-right {
        float: none;
      }
      .page-text {
        width: 100% !important;
      }
      .page-pic {
        margin: 20px 0 0 0;
      }
    }
  }
}
.page-2col_sub {
  @extend .page-2col;
  margin-bottom: 75px;
  .page-left {
    width: 370px;
  }
  .page-right {
    width: 500px;
  }
  .page-text {
    width: 100%;
  }
  @include mqSP {
    margin-bottom: 40px;
    .page-left {
      width: 100%;
      .button {
        margin: 0 auto;
      }
    }
    .page-right {
      width: 100%;
    }
  }
}


.page-note {
  @extend .page-text;
  margin-top: -20px;
  font-size: 13px;
  color: #666;
  @include mqSP {
    margin-top: 0px;
    font-size: 11px;
  }
}


.page-nav {
  @include clearfix;
  width: 930px;
  margin: 0 auto;
  padding-bottom: 100px;
  font-size: 16px;
  p {
    &:first-child {
      float: left;
    }
    &:last-child {
      float: right;
    }
    a {
      display: block;
    }
  }
  @include mqSP {
    width: 100%;
    padding: 0 10px;
    margin: 0 0 40px 0;
    font-size: 12px;
    p {
      margin-bottom: 10px;
      width: 90%;
      line-height: 1.7;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
      a {
        padding: 5px 0px;
      }
    }
  }
}