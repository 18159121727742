.l-page-header {
  position: relative;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  &.page-header_vision {
    background-image: url(../img/vision-heading.png);
  }
  &.page-header_oem {
    background-image: url(../img/oem-heading.png);
  }
  &.page-header_products {
    background-image: url(../img/products-heading.png);
  }
  &.page-header_policy {
    background-image: url(../img/policy-heading.png);
  }
  // &.page-header_aoba {
  //   background-image: url(../img/aoba-heading.png);
  // }
  &.page-header_news {
    background-image: url(../img/news-heading.png);
  }


  span {
    display: block;
    text-align: center;
    color: #fff;
    width: 100%;
    text-shadow: 0px 0px 4.65px rgba(7, 0, 2, 0.75);
    font-size: 36px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    letter-spacing: .08em;
  }
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: $c_blue;
    opacity: .7;
  }
  @include mqSP {
    height: 100px;
    span {
      font-size: 21px;
    }
  }
}