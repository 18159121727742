.policy-heading {
  font-size: 18px;
  font-weight: bold;
  margin: 40px 0 10px 0;
  @include mqSP {
    font-size: 14px;
    padding: 0 10px;
    margin: 30px 0 10px 0;
  }

}
.policy-text {
  line-height: 1.87;
  font-size: 16px;
  @include mqSP {
    font-size: 12px;
    padding: 0 10px;
  }
}
