.l-footer {
  width: 100%;
  min-width: 1090px;
  background: $c_blue;
  color: #fff; 
  font-size: 13px;
  line-height: 2.15;
  @include mqSP {
    min-width: 100%;
    font-size: 9px;
    line-height: 1.8;
  }
}

.footer-inner {
  @include clearfix;
  width: 1070px;
  margin: 0 auto;
  padding: 70px 0 100px 0;
  @include mqSP {
    width: 100%;
    padding: 0;
  }
}

.footer-heading {
  font-size: 15px;
  margin-bottom: 25px;
  line-height: 1;
  @include mqSP {
    font-size: 11px;
  }
}


.footer-address,
.footer-map,
.footer-nav {
  width: calc(100% / 3);
  float: left;
  @include mqSP {
    float: none;
    width: 100%;
    border-bottom: 1px solid #808080;
  }
}

.footer-address {
  @include mqSP {
    padding: 20px 10px 30px;
  }
}

.footer-map {
  @include mqSP {
    padding: 25px 10px;
  }
}
.footer-gmap {
  width: 297px;
  height: 200px;
  background: #ccc;
  @include mqSP {
    width: 100%;
  }
}
.footer-map-text {
  width: 297px;
  margin: 10px 0 0 0;
  text-align: right;
  a {
    font-size: 11px;
  }
  @include mqSP {
    width: 100%;
    a {
      font-size: 9px;
    }
  }
}

.footer-nav {
  @include mqSP {
    padding: 25px 10px;
  }

}


.footer-copy {
  border-top: 1px solid #a4a4a4;
  p {
    width: 1070px;
    margin: 0 auto;
    padding: 25px 0;
    color: #b3b3b3;
    font-size: 11px;
  }
  @include mqSP {
    border: none;
    p {
      width: 100%;
      padding: 20px 10px;
      text-align: center;
      font-size: 9px;
    }
  }
}