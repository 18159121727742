//
// ニュース
//
.l-top-news {
  background: url(../img/background_gray.png) repeat;
  padding: 85px 0;
  @include mqSP {
    padding: 40px 0;
  }
}
.top-news-inner {
  width: 930px;
  margin: 0 auto;
  .button {
    margin: 0 auto;
  }
  @include mqSP {
    width: 100%;
    padding: 0 10px;
  } 
}
.top-news-list {
  width: 820px;
  // margin: 0 auto 55px auto;
  margin: 0 auto;
  li {
    margin-bottom: 35px;
    text-align: center;
    dl {
      @include clearfix;
      display: inline-block;
      position: relative;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include mqSP {
    width: 100%;
    // margin: 0 0 35px 0;
    li {
      margin: 0 0 25px 0;
    }
  }
}
.top-news-list-date {
  font-size: 16px;
  width: 120px;
  float: left;
  position: relative;
  top: 5px;
  @include mqSP {
    position: static;
    float: none;
    width: 100%;
    font-size: 11px;
    margin: 0 0 15px 0;
  }
}
.top-news-list-category {
  font-size: 13px;
  background: #333;
  color: #fff;
  width: 110px;
  height: 24px;
  float: left;
  text-align: center;
  margin: 0 0 3px 0;
  padding: 6px 0;
  @include mqSP {
    font-size: 9px;
    float: none;
    width: 77px;
    height: 17px;
    position: absolute;
    right: 0;
    top: -5px;
    padding: 4px 0;

  }
}
.top-news-list-title {
  font-size: 16px;
  // width: 555px;
  max-width: 555px;
  // float: right;
  float: left;
  line-height: 1.85;
  position: relative;
  top: -4px;
  @include mqSP {
    font-size: 12px;
    width: 100%;
    float: none;
    line-height: 1.66;
  }
}
