.l-breadcrumb {
  width: 1070px;
  margin: 0 auto 80px auto;
  padding: 15px 0;
  ul {
    // @include clearfix;
    li {
      // float: left;
      display: inline;
      font-size: 13px;
      color: #4c4c4c;
      &:after {
        content: "";
        display: inline-block;
        width: 6px;
        height: 11px;
        margin: 0 10px;
        background-image: url(../img/breadcrumb-arrow.png);
        background-repeat: no-repeat;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      a {
        text-decoration: underline;
      }
    }
  }
  @include mqSP {
    width: 100%;
    padding: 15px 10px;
    margin-bottom: 20px;
    ul {
      li {
        font-size: 10px;
        &:after {
          width: 3px;
          height: 6px;
          margin: 0 10px;
          background-size: 3px auto;
        }
      }
    }
  }
}
