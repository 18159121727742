//
// トップ汎用セクション
//
.l-top-section {
  padding: 100px 0 90px;
  @include mqSP {
    padding: 40px 0;
  }
}
.top-section-inner {
  position: relative;
  width: 930px;
  margin: 0 auto;
  overflow: visible;
  .section-heading {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    width: 390px;
  }
  &:after {
    content: "";
    position: absolute;
  }
  @include mqSP {
    width: 100%;
    padding: 0 10px;
    .section-heading {
      text-align: center;
      width: 100%;
    }
    &:after {
      display: none;
    }
    .button {
      margin: 0 auto;
    }
  }
}
.top-section-text {
  font-size: 16px;
  line-height: 1.87;
  margin: 0 0 50px 0;
  @include mqSP {
    font-size: 12px;
    line-height: 1.66;
    text-align: center;
    margin: 0 0 35px 0;
    &:after {
      content: "";
      position: relative;
      display: block;
      margin: 30px auto 0 auto;
      width: calc(100% + 20px);
      left: -10px;
      background-repeat: no-repeat;
    }
  }
}

//
// 酒造り
//
.top-vision {
  .top-section-inner {
    &:after {
      right: -70px;
      top: -45px;
      width: 578px;
      height: 338px;
      background-image: url(../img/top-vision.png);
      background-repeat: no-repeat;
      background-position: right center;
    }
  }
  @include mqSP {
    .top-section-text {
      &:after {
        // content: url("../img/top-vision_sp.png");
        background-image: url(../img/top-vision_sp.png);
        background-size: 100% auto;
        // width: 100%;
        // height: 190px;
        padding: 63% 0 0;
      }
    }
  }
}

//
// 商品詳細
//
.top-products {
  .top-section-inner {
    &:after {
      right: -155px;
      top: -55px;
      width: 819px;
      height: 432px;
      background-image: url(../img/top-products.png);
      background-repeat: no-repeat;
      background-position: right center;
    }
  }
  @include mqSP {
    .top-section-text {
      &:after {
        // content: url(../img/top-products_sp.png);
        background-image: url(../img/top-products_sp.png);
        background-size: 100% auto;
        // width: 100%;
        padding: 67% 0 0;
      }
    }
  }
}
