.l-news {
  @include mqSP {
    padding: 0 10px;
  }
}
.news-date {
  font-size: 20px;
  margin-bottom: 30px;
  @include mqSP {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.news-heading {
  position: relative;
  margin: 0 0 50px 0;
  padding: 0 0 30px 0;
  font-size: 32px;
  line-height: 1.3125;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    background: #000;
  }
  &:before {
    height: 2px;
    bottom: 0;
  }
  &:after {
    height: 1px;
    bottom: -3px;
  }
  @include mqSP {
    font-size: 21px;
    margin: 0 auto 20px;
    padding: 0 0 20px 0;
  }
}


.news-body {
  font-size: 18px;
  line-height: 2;
  p {
    margin: 0 0 50px 0;
    &:empty {
      margin: 0;
      line-height: 0;
      height: 0;
      font-size: 0;
    }
  }
  ul, ol {
    margin: 0 0 50px 0;
    padding: 0 0 0 25px;
    li {
      margin: 0 0 10px 0;
      font-size: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  @include mqSP {
    font-size: 16px;
    line-height: 1.6;
    p,ul,ol {
      margin-bottom: 25px;
    }
  }

}


.news-notes {
  background: #f0f0f0;
  color: #666;
  padding: 25px;
  font-size: 16px;
  line-height: 1.62;
  margin-top: 50px;
  .news-note-heading {
    font-weight: bold;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include mqSP {
    margin-top: 20px;
    padding: 15px;
    font-size: 14px;
    .news-note-heading {
      margin: 0 0 10px 0;
    }
  }

}