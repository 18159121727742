.l-header {
  width: 100%;
  min-width: 1090px;
  background-color: rgba(255,255,255,.5);
  box-shadow: 0px 0px 7px 0px rgba(7, 0, 2, 0.6);
  border-top: 7px solid $c_blue;
  box-sizing: border-box;
  @include mqSP {
    border-top: 4px solid $c_blue;
    min-width: 100%;
  }
}
.header-inner {
  position: relative;
  width: 1070px;
  height: 120px;
  margin: 0 auto;
  @include mqSP {
    width: 100%;
    height: 55px;
  }
}

.header-logo {
  position: absolute;
  left: 0;
  top: calc(50% - 26px);
  width: 167px;
  height: 53px;
  background-image: url('../img/logo.png');
  background-repeat: no-repeat;
  background-size: 167px auto;
  background-repeat: no-repeat;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  a {
    display: block;
    width: 167px;
    height: 53px;
  }
  @include mqSP {
    left: calc(50% - 53px);
    top: calc(50% - 16px);
    width: 106px;
    height: 33px;
    background-size: 106px auto;
  }
}

.header-tel {
  position: absolute;
  top: 25px;
  right: 225px;
  a {
    color: $c_blue;
    font-size: 29px;
    text-decoration: none;
    span {
      &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 24px;
        padding: 0 7px 0 0;
        background-image: url('../img/icon_tel.png');
        background-repeat: no-repeat;
        background-size: 20px auto;
      }
    }
  }
  @include mqSP {
    display: none;
  }
}

.header-contact {
  position: absolute;
  top: 20px;
  right: 0;
  a {
    display: block;
    background: $c_blue;
    color: $c_gold;
    width: 210px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    span {
      &:before {
       content: "";
        display: inline-block;
        position: relative;
        top: 2px;
        width: 18px;
        height: 14px;
        padding: 0 7px 0 0;
        background-image: url('../img/icon_mail_header.png');
        background-repeat: no-repeat;
        background-size: 18px auto;

      }
    }
  }
  @include mqSP {
    display: none;
  }
}

.header-nav {
  position: absolute;
  right: 0;
  bottom: 20px;
  ul {
    @include clearfix;
    li {
      float: left;
      margin: 0 0 0 35px;
      a {
        text-decoration: none;
        padding-bottom: 8px;
        &.is-current {
          border-bottom: 2px solid $c_gold;
        }
      }
    }
  }
  @include mqSP {
    bottom: initial;
    top: 55px;
    z-index: 9999;
    width: 100%;
    height: 0;
    background-color: rgba($c_blue,.9);
    transition: height .3s ease;
    overflow: hidden;
    ul {
      width: 100%;
      li {
        float: none;
        width: 100%;
        margin: 0;
        a {
          display: block;
          color: #fff;
          height: 40px;
          padding: 0 10px;
          line-height: 40px;
          font-size: 15px;
          border-bottom: 1px solid rgba($c_gold,.5);
          &.is-current {
            border-bottom: 1px solid rgba($c_gold,.5);
          }
        }
      }
    }
    body.nav-open & {
      height: 200px;
    }
  }
}


.header-hamburger {
  display: none;
  @include mqSP {
    display: block;
    position: absolute;
    right: 10px;
    top: 31px;
    width: 30px;
    text-align: center;
    a {
      display: block;
      width: 30px;
      height: 0px;
      border-top: 3px solid $c_blue;
      color: $c_blue;
      transition: border-color .3s ease;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 30px;
        height: 3px;
        background: $c_blue;
        transition: transform .3s ease;
      }
      &:before {
        bottom: -8px;
      }
      &:after {
        top: -8px
      }
      span {
        display: block;
        position: absolute;
        top: -21px;
        left: 0;
        font-size: 8px;
        font-family: $f_go;
        font-weight: bold;
        letter-spacing: -.05em;
      }
      body.nav-open & {
        border-color: transparent;
        &:before {
          transform: translateY(-7px) rotate(45deg);
        }
        &:after {
          transform: translateY(9px) rotate(-45deg);
        }
      }
    }
  }
}
