.l-top-kv {
  position: relative;
  top: 0px;
  z-index: 0;
  // height: 100vh;
  width: 100%;
  height: 490px;
  overflow: hidden;
  @include mqSP {
    height: 320px;
  }
}
.top-kv-inner {
  width:100%;
  height: 490px;
  @include mqSP {
    height: 320px;
  }
}
.top-kv-slide {
    width: 100%;
    height: 490px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include mqSP {
      height: 320px;
    }
    &.slide01 {
      background-image: url(../img/kv01.png);
      @include mqSP {
        background-image: url(../img/kv01_sp.png);
      }
    }
    &.slide02 {
      background-image: url(../img/kv02.png);
      @include mqSP {
        background-image: url(../img/kv02_sp.png);
      }
    }
    &.slide03 {
      background-image: url(../img/kv03.png);
      @include mqSP {
        background-image: url(../img/kv03_sp.png);
      }
    }
    &.slide04 {
      background-image: url(../img/kv04.png);
      @include mqSP {
        background-image: url(../img/kv04_sp.png);
      }
    }
}

.top-kv-copy {
  position: absolute;
  bottom: 75px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 42px;
  color: #fff;
  text-shadow: 0px 0px 4.5px rgba(7, 0, 2, 0.74);
  span {
    display: block;
    margin: 0 0 10px 0;
    font-size: 29px;
  }
  @include mqSP {
    font-size: 27px;
    line-height: 1.15;
    bottom: 50px;
    letter-spacing: 0;
    span {
      margin-bottom: 5px;
      font-size: 20px;
    }
  }
}