@charset "UTF-8";
/*---------------------------------------------------------*/
/* ベース */
/*---------------------------------------------------------*/
html,body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: $f_min;
  -webkit-text-size-adjust: 100%;
  // letter-spacing: .08em;
  word-wrap: break-word;
  color: $color-text;
  @include fz(14);
  background: url(../img/background.png) repeat;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: underline;
  &:hover {
    text-decoration:none;
    opacity: 0.7;
  }
}

img {
  vertical-align: bottom;
}

small {
  font-size: 11px;
  font-size: 1.1rem;
}
