.section-header {
  text-align: center;
}
.section-heading {
  margin: 0 0 40px 0;
  padding: 0 40px 10px 40px;
  color: $c_blue;
  font-size: 36px;
  display: inline-block;
  border-bottom: 1px solid $c_blue;
  span {
    font-size: 26px;
  }
  @include mqSP {
    font-size: 21px;
    display: block;
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0 0 10px 0;
    line-height: 1.14;
    span {
      font-size: 16px;
    }

  }
}
.page-heading {
  font-size: 36px;
  line-height: 1.27;
  color: $c_blue;
  border-bottom: 1px solid $c_blue;
  padding: 0 0 15px 0;
  margin: 0 0 50px 0;
  @include mqSP {
    font-size: 21px;
    padding: 0 0 10px 0;
    margin: 0 auto 20px auto;
    width: calc(100% - 20px);
  }
}
.page-heading_sub {
  font-size: 26px;
  color: #2c77db;
  line-height: 1.7;
  border-left: 4px solid #2c77db;
  padding: 0 0 0 15px;
  margin: 0 0 25px 0;
  @include mqSP {
    margin: 0 10px 20px 10px;
    font-size: 17px;
  }
}