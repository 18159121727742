.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  text-align: center;
  font-weight: normal;
  line-height: 15px;

  @include mqSP {
    position: fixed;
    right: 10px;
    bottom: 10px;
    text-align: center;
    font-weight: normal;
    line-height: 15px;
  }
}
 .pagetop a {
    display: block;
    border-radius: 6px;
    background-color: #333333;
    opacity: 0.8;
    width: 76px;
    height: 76px;
    color: #fff;
    font-size: 10px;
    text-decoration: none;
    padding: 44px 0 0 0;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 25px;
      left: calc(50% - 10px);
      width: 20px;
      height: 20px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(-45deg);
    }
    @include mqSP {
      display: table-cell;
      vertical-align: middle;
      border-radius: 6px;
      background-color: #333333;
      opacity: 0.8;
      width: 80px;
      height: 80px;
      padding-top: 35px;
      font-size: 8px;
      transform: scale(.7);
      &:before {
        top: 25px;
      }
    }
  }