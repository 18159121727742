.list-check {
  @include clearfix;
  li {
    float: left;
    font-size: 20px;
    margin: 0 25px 0 0;
    &:before {
      content: "";
      display: inline-block;
      width: 23px;
      height: 20px;
      position: relative;
      top: 2px;
      margin: 0 5px 0 0;
      background-image: url(../img/icon_check.png);
      background-repeat: no-repeat;
    }
  }
  @include mqSP {
    li {
      font-size: 16px;
      float: none;
      margin: 0 0 5px 0;
      &:before {
        width: 15px;
        height: 15px;
        background-size: 15px auto;
      }
    }
  }
}

.list-dot {
  line-height: 1.87;
  font-size: 16px;
  margin: 10px 0 0 0;
  li {
    position: relative;
    padding: 0 0 0 15px;
    &:before {
      content:  "・";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  @include mqSP {
    font-size: 12px;
    padding: 0 10px;
  }

}