.products-header {
  width: 1070px;
  text-align: center;
  line-height: 1.87;
  font-size: 16px;
  margin: 0 auto 40px auto;
  @include mqSP {
    width: 100%;
    font-size: 14px;
    text-align: left;
    padding: 0 10px;
  }
}
.products-recommend {
  background: url(../img/background_products.png) repeat;
  padding: 60px 0 70px 0;
  @include mqSP {
    padding: 20px 0 10px 0;
  }
}
.products-section {
  padding: 60px 0 70px 0;
  @include mqSP {
    padding: 20px 0 10px 0;
  }
}

.products-inner {
  width: 1070px;
  margin: 0 auto;
  @include mqSP {
    padding: 0 10px;
    width: 100%;
  }
}

.products-heading {
  margin:0 0 30px 0;
  font-size: 36px;
  text-align: center;
  .products-recommend & {
    color: #7c400c;
  }
  .products-section & {
    color: $c_blue;
  }
  @include mqSP {
    font-size: 22px;
    margin: 0 0 20px 0;
  }
}

.products-list {
  display: flex;
  flex-wrap: wrap;
  li {
    width: calc(25% - 3px);
    background: #fff;
    margin-right: 4px;
    margin-top: 40px;
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 0;
    }
    dl {
      dt {
        line-height: 0;
        img {
          width: 100%;
          vertical-align: top;
        }
      }
      dd {
        padding: 20px;
      }
    }
    a {
      display: block;
      text-decoration: none;
    }
  }
  @include mqSP {
    li {
      width: calc(50% - 2.5px);
      margin-right: 5px;
      margin-top: 5px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 5px;
      }
      dl {
        dd {
          padding: 15px 10px;
        }
      }
    }
  }
}
.product-name {
  text-align: center;
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
  font-weight: bold;
  margin: 0 0 10px 0;
  @include mqSP {
    font-size: 14px;
  }
}
.product-text {
  font-size: 13px;
  line-height: 1.52;
  text-align: center;
  .products-recommend & {
    text-align: left;
  }
  @include mqSP {
    font-size: 12px;
  }

}



.bg-gray {
  background: url(../img/background_gray.png) repeat;
}

.products-shop {
  width: 1070px;
  margin: 0 auto;
  text-align: center;
  padding: 70px 0;
  ul {
    li {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include mqSP {
    width: 100%;
    padding: 40px 20px 60px 20px;
  }
}
.products-shop-heading {
  font-size: 24px;
  text-align: center;
  margin: 0 0 40px 0;
  @include mqSP {
    font-size: 18px;
    margin: 0 0 20px 0;
    line-height: 1.4;
  }
}
.products-shop-text {
  font-size: 13px;
  margin-bottom: 10px;
  @include mqSP {
    font-size: 12px;
    line-height: 1.87;
    text-align: left;
    margin-bottom: 15px;
  }
}
.link-arrow {
  display: inline;
  font-size: 16px;
  color: $c_blue;
  &:before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 11px;
    background-image: url(../img/icon_arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    margin: 0 5px 0 0;
  }
  @include mqSP {
    font-size: 13px;
  }
}


.notfound-text {
  @extend .page-text;
  text-align: center;
  &:last-of-type {
    margin-bottom: 40px;
  }
  @include mqSP {
    text-align: left;
    &:last-of-type {
      margin-bottom: 30px;
    }
  }
}