//
// OEM
//
.l-top-oem {
  background-image: url(../img/background_oem.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  padding: 100px 0;
  @include mqSP {
    background-image: url(../img/background_oem_sp.png);
    padding: 40px 10px;
  }
}
.top-oem-inner {
  width: 1070px;
  margin: 0 auto;
  .button {
    margin: 0 auto;
  }
  @include mqSP {
    width: 100%;
  }
}
.top-oem-text {
  font-size: 16px;
  line-height: 1.87;
  text-align: center;
  margin: 0 0 50px 0;
  @include mqSP {
    font-size: 12px;
    line-height: 1.66;
    text-align: center;
    margin: 0 0 35px 0;
  }
}
.top-oem-achievement {
  background-color: rgba(#fff, .7);
  width: 100%;
  padding: 30px 0;
  margin: 55px 0 0 0;
  @include mqSP {
    padding: 20px 0;
    margin: 35px 0 0 0;
  }
}
.top-oem-achievement-heading {
  font-size: 26px;
  text-align: center;
  color: $c_blue;
  margin: 0 0 35px 0;
  @include mqSP {
    font-size: 16px;
    margin: 0 0 20px 0 ;
  }
}

.top-oem-achievement-title {
  font-size: 36px;
  color: $c_blue;
  display: block;
  margin-bottom: 15px;
  @include mqSP {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.top-oem-achievement-text {
  font-size: 16px;
  line-height: 1.5;
  @include mqSP {
    font-size: 9px;
  }
}

.top-oem-achievement-list {
  @include clearfix;
  width: 1012px;
  margin: 0 auto;
  li {
    width: 496px;
    height: 180px;
    float: left;
    border: 1px solid #ccc;
    background-image: url(../img/top-oem-kahaku.png);
    background-size: auto 100%;
    background-position: right center;
    background-repeat: no-repeat;
    &:nth-child(2) {
      float: right;
      background-image: url(../img/top-oem-aoba.png);
    }
    a {
      display: block;
      text-decoration: none;
      height: 180px;
      width: 100%;
      padding: 25px;
    }
  }
  @include mqSP {
    width: 100%;
    padding: 0 10px;
    li {
      width: 100%;
      height: 100px;
      float: none;
      margin: 0 0 10px 0;
      background-image: url(../img/top-oem-kahaku_sp.png);
      &:nth-child(2) {
        float: none;
        background-image: url(../img/top-oem-aoba_sp.png);
      }
      a {
        padding: 15px;
      }
    }
  }
}
